import React, { useState } from "react";
import { useParams } from "react-router-dom";

import { useData } from "../../utils/useData.tsx";

import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

import "./BookPages.scss";
import { Chapter } from "../../types/index.ts";

export const BookPages = () => {
  const { id } = useParams();
  const filterNumber = parseInt((id ?? "id=0").replace("id=", ""), 10);
  const controll = filterNumber !== 0 ? filterNumber - 1 : 0;

  const { dataAll } = useData<Chapter>("chapters");

  const [controllChapter, setControllChapter] = useState(controll);
  const [controllPage, setControllPage] = useState(0);

  const handlePrev = () => {
    if (dataAll) {
      if (controllPage > 0) {
        setControllPage((prev) => prev - 1);
      } else if (controllChapter > 0) {
        setControllChapter((prev) => prev - 1);
        setControllPage(dataAll[controllChapter - 1].pages.length - 1);
      }
    }
  };

  const handleNext = () => {
    if (dataAll) {
      if (controllPage < dataAll[controllChapter]?.pages.length - 1) {
        setControllPage((prev) => prev + 1);
      }
      if (
        controllPage >= dataAll[controllChapter]?.pages.length - 1 &&
        dataAll[controllChapter + 1]?.pages.length > 0
      ) {
        setControllChapter((prev) => prev + 1);
        setControllPage(0);
      }
    }
  };

  const chapter = dataAll && dataAll[controllChapter];

  const sortPages = chapter?.pages.sort((a, b) =>
    a.number > b.number ? 1 : -1
  );

  const page = sortPages && sortPages[controllPage];

  const isLastPage = chapter && controllPage >= chapter.pages.length - 1;

  return (
    <div className="container-itens container-page">
      <div className="wall-title">
        <h2>
          Capítulo <span>{chapter?.number}</span>: <u>{chapter?.title}</u>
        </h2>
        <p>Número de páginas: {chapter?.pages.length}</p>
      </div>

      <div className="container-wall-content">
        <div
          className={`wall-content-image ${
            page?.file && page?.content ? " " : " wall-content-all"
          }`}
        >
          {chapter && (
            <>
              <div className="wall-content">
                <div className="wall-scroll">
                  <p>{page?.content}</p>
                </div>
              </div>
              {page?.file && (
                <div className="wall-image">
                  <img
                    src={`${process.env.REACT_APP_HOST_IMAGE}${page?.file}`}
                    alt={page?.alt}
                    width={510}
                    height={510}
                  />
                </div>
              )}
            </>
          )}
        </div>
      </div>

      <div className="float-Button left">
        <button
          className="primary"
          onClick={handlePrev}
          disabled={controllChapter <= 0 && controllPage <= 0}
        >
          <span>
            <NavigateBeforeIcon fontSize="large" />
          </span>
        </button>
      </div>

      <div className="float-Button right">
        <button
          className="primary"
          onClick={handleNext}
          disabled={
            isLastPage &&
            dataAll &&
            !(dataAll[controllChapter + 1]?.pages.length > 0)
          }
        >
          <span>
            <NavigateNextIcon fontSize="large" />
          </span>
        </button>
      </div>

      <div className="button-index-page">
        <button
          className="primary"
          onClick={handlePrev}
          disabled={controllChapter <= 0 && controllPage <= 0}
        >
          <span>
            <NavigateBeforeIcon fontSize="large" />
          </span>
        </button>

        <div className="wall-index-item">
          <div>
            <p>Página: {page?.number ?? chapter?.pages.length}</p>
          </div>
        </div>

        <button
          className="primary"
          onClick={handleNext}
          disabled={
            isLastPage &&
            dataAll &&
            !(dataAll[controllChapter + 1]?.pages.length > 0)
          }
        >
          <span>
            <NavigateNextIcon fontSize="large" />
          </span>
        </button>
      </div>
    </div>
  );
};
