import React, { useState } from "react";
import Cookies from "js-cookie";

import { fetchData } from "../../../services/AdminService.ts";
import "./Login.scss";
import { Snackbar } from "@mui/material";

export const Login = () => {
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState<string | null>(null);
  const [active, setActive] = useState(false);

  const handleClick = async () => {
    if (name && password) {
      try {
        await fetchData(name, password);
        setError(null);
      } catch (err) {
        setActive(true);
        setError(err.message);
        setInterval(() => setActive(false), 2000);
      }
    }

    const token = Cookies.get("authToken");

    if (token) {
      window.location.href = "/admin";
    }
  };
  return (
    <div className="container-login">
      <div className="login">
        <div className="login-item">
          <label htmlFor="name">Nome:</label>
          <input
            type="text"
            name="name"
            placeholder="Username"
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="login-item">
          <label htmlFor="password">Senha:</label>
          <input
            type="password"
            name="password"
            id=""
            placeholder="Password"
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <button className="Login-button" onClick={handleClick}>
          Logar
        </button>
      </div>
      {error && <Snackbar open={active} message={error} />}
    </div>
  );
};
