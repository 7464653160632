import React from "react";

import "./Header.scss";

export const Header = () => {
  return (
    <div className="container-header">
      <div className="logo"></div>
      <div className="header-title">
        <h1>Éligos: The Sacred Guardian of Magic</h1>
      </div>
    </div>
  );
};
