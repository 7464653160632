import * as React from "react";

import { styled } from "@mui/material/styles";

import Card from "@mui/material/Card";

import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function RecipeReviewCard({ id, name, content, alt, file }) {
  const [expanded, setExpanded] = React.useState(false);
  const [controllImg, setControllImg] = React.useState(0);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const splitImage = file != null && file.split(",");

  const handleClickNext = () => {
    if (splitImage.lenght !== 0 && !(splitImage.lenght - 1)) {
      setControllImg((prev) => prev + 1);
    }
  };

  return (
    <Card
      sx={{
        maxWidth: 345,
        position: "relative",
        borderRadius: "4px 4px 0px 0px",
        overflow: "inherit",
      }}
    >
      <button
        onClick={() => setControllImg((prev) => prev - 1)}
        disabled={controllImg <= 0}
        className="button-img left"
      >
        <ArrowBackIosIcon fontSize="small" />
      </button>
      <CardMedia
        sx={{ marginRight: 1 }}
        component="img"
        height="500"
        image={`${process.env.REACT_APP_HOST_IMAGE}${splitImage[controllImg]}`}
        alt={alt}
      />
      <button
        onClick={handleClickNext}
        disabled={controllImg === splitImage.length - 1}
        className="button-img right"
      >
        <ArrowForwardIosIcon fontSize="small" />
      </button>

      <CardContent>
        <Typography variant="h4" color="text.secondary">
          {name}
        </Typography>
      </CardContent>
      <CardActions disableSpacing>
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions>
      <Collapse
        in={expanded}
        timeout="auto"
        unmountOnExit
        sx={{
          position: "absolute",
          backgroundColor: "white",
          zIndex: "90",
          borderRadius: "0px 0px 4px 4px",
          boxShadow: "0px 20px 20px 0px black",
          width: "-webkit-fill-available",
        }}
      >
        <CardContent>
          <Typography paragraph>{content}</Typography>
        </CardContent>
      </Collapse>
    </Card>
  );
}
