import React from "react";

import "./Inicial.scss";
import { Cards } from "../components/Cards/Cards.tsx";
import LogoutIcon from "@mui/icons-material/Logout";
import { Logout } from "../../../services/AdminService.ts";

const cards = [
  {
    title: "Novidades",
    hrefPost: "/admin/news/add",
    hrefEdit: "/admin/news/edit",
    hrefDelete: "/admin/news/delete",
    description: "Adicione, Edite ou Exclua uma Novidades do mural",
  },
  {
    title: "Livro",
    hrefPost: "/admin/book/add",
    hrefEdit: "/admin/book/edit",
    hrefDelete: "/admin/book/delete",
    description: "Adicione, Edite ou Exclua um livro",
  },
  {
    title: "Capítulo",
    hrefPost: "/admin/chapter/add",
    hrefEdit: "/admin/chapter/edit",
    hrefDelete: "/admin/chapter/delete",
    description: "Adicione, Edite ou Exclua um capítulo",
  },
  {
    title: "Página",
    hrefPost: "/admin/page/add",
    hrefEdit: "/admin/page/edit",
    hrefDelete: "/admin/page/delete",
    description: "Adicione, Edite ou Exclua uma Página",
  },
  {
    title: "Universo",
    hrefPost: "/admin/universe/add",
    hrefEdit: "/admin/universe/edit",
    hrefDelete: "/admin/universe/delete",
    description: "Adicione, Edite ou Exclua um personagem do universo do livro",
  },
];

export const Inicial = () => {
  return (
    <div className="container-admin">
      <div className="logout" onClick={() => Logout()}>
        <span title="Logout">
          <LogoutIcon />
        </span>
      </div>
      <h1>Página de Administração</h1>
      <div className="container-cards">
        {cards.map((item) => (
          <Cards
            title={item.title}
            hrefPost={item.hrefPost}
            hrefEdit={item.hrefEdit}
            hrefDelete={item.hrefDelete}
            description={item.description}
          />
        ))}
      </div>
    </div>
  );
};
