import React from "react";

export const useValidFile = () => {
  const [file, setFile] = React.useState<File>(null);
  const [onlyImage, setOnlyImage] = React.useState<boolean>(false);

  const validImageTypes = [
    "image/jpeg",
    "image/jpg",
    "image/png",
    "image/gif",
    "image/bmp",
    "image/webp",
    "image/tiff",
    "image/svg+xml",
  ];

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      if (validImageTypes.includes(e.target.files[0].type)) {
        setFile(e.target.files[0]);
        setOnlyImage(false);
      } else {
        setOnlyImage(true);
      }
    }
  };

  return { file, onlyImage, handleFileChange };
};
