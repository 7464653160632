import React, { useState } from "react";

import { Alert, Box, Snackbar, TextField } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import "../../../../assets/styles/layouts/_container-form.scss";
import "../../../../assets/styles/components/_buttonForm.scss";
import "./Post.scss";

import SimpleBackdrop from "../../components/Alert/Background.tsx";
import { fetchDataNewItem } from "../../../../services/AdminService.ts";
import { useValidFile } from "../../utils/useValidFile.tsx";

const styles = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  backgroundColor: "black",
  borderRadius: "30px",
  border: "1px solid white",
  padding: "30px 20px",
  boxShadow: "0px 0px 4px 0px white",
};

export const PostNews = () => {
  const [post, setPost] = useState(false);
  const [title, setTitle] = useState("");
  const [newscol, setNewscol] = useState("");
  const [alt, setAlt] = useState("");
  const [error, setError] = useState<string | null>(null);

  const { file, onlyImage, handleFileChange } = useValidFile();

  async function handleClick(
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) {
    e.preventDefault();
    const formData = new FormData();
    formData.append("title", title);
    formData.append("newscol", newscol);
    formData.append("file", file);
    formData.append("alt", alt);

    try {
      await fetchDataNewItem("news", formData);
      setPost(true);
    } catch (err) {
      setError(err.message);

      setInterval(() => setError(null), 2000);
    }
  }

  return (
    <div className="container-form book-add news">
      <Box sx={styles}>
        <div className="back-nav" onClick={() => window.history.back()}>
          <span>
            <ArrowBackIcon />
          </span>
        </div>
        <h1>Adicionar uma nova novidade</h1>

        <div>
          <div>
            <TextField
              id="outlined-textarea"
              label={"Título"}
              placeholder={"Título"}
              type="text"
              sx={{
                border: "1px solid rgba(255, 255, 255, 0.3607843137)",
                marginRight: "10px",
              }}
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
            <TextField
              id="outlined-textarea"
              label={"Descrição da imagem"}
              placeholder={"Descrição da imagem"}
              type="text"
              sx={{
                border: "1px solid rgba(255, 255, 255, 0.3607843137)",
                marginLeft: "10px",
              }}
              value={alt}
              onChange={(e) => setAlt(e.target.value)}
            />
          </div>
          <div className="input-page" style={{ display: "inherit" }}>
            <div className="input-file">
              <input
                type="file"
                accept="image/*"
                onChange={(e) => handleFileChange(e)}
              />
            </div>
          </div>
          <TextField
            id="outlined-textarea"
            label={"conteúdo"}
            placeholder={"conteúdo"}
            multiline
            rows={4}
            sx={{
              border: "1px solid rgba(255, 255, 255, 0.3607843137)",
              marginTop: "10px",
              width: "100%",
            }}
            value={newscol}
            onChange={(e) => setNewscol(e.target.value)}
          />
        </div>

        <button
          className="button-form"
          onClick={(e) => handleClick(e)}
          style={{ marginRight: 0 }}
        >
          Enviar
        </button>
      </Box>
      {post && <SimpleBackdrop setActive={true} />}
      {onlyImage && (
        <div className="error">
          <Alert
            severity="error"
            sx={{
              position: "fixed",
              bottom: "5px",
              left: "43%",
              color: "black",
            }}
          >
            Apenas imagens
          </Alert>
        </div>
      )}
      {error && (
        <div className="alert-error">
          <Snackbar
            open={error !== null}
            message={error}
            sx={{ border: "none" }}
          />
        </div>
      )}
    </div>
  );
};
