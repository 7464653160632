import React, { useState } from "react";

import { fetchDataDelete } from "../../../../services/AdminService.ts";

import { useData } from "../../utils/useData.tsx";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";

import DeleteIcon from "@mui/icons-material/Delete";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import "../../../../assets/styles/layouts/_container-form.scss";
import "../../../../assets/styles/components/_buttonForm.scss";
import "./Delete.scss";
import SimpleBackdrop from "../../components/Alert/Background.tsx";
import { BookEdit } from "../../types/index.ts";

export const DeleteChapter = () => {
  const { dataAll } = useData("book");

  const [deleted, setDeleted] = useState(false);

  const handleClick = (id: number) => {
    fetchDataDelete("/chapters/delete/", id);
    setDeleted(true);
  };

  return (
    <div className="container-form delete">
      <div className="form-item">
        <div className="form-item-header">
          <div onClick={() => window.history.back()}>
            <ArrowBackIcon />
          </div>
          <h1>Excluír Capítulos</h1>{" "}
        </div>

        <div className="container-itens-edit">
          {dataAll.length > 0 &&
            dataAll.map((item: BookEdit) => (
              <div className="itens-render">
                <h2>Livro: {item.title}</h2>
                <p>Capítulos: </p>
                {item.chapters.map((element) => (
                  <Accordion
                    className=" summary-item"
                    key={element.id_chapter}
                    sx={{ backgroundColor: "#ffffffe3" }}
                  >
                    <AccordionSummary
                      aria-controls="panel1-content"
                      id="panel1-header"
                      className="summary-title"
                      key={element.id_chapter}
                    >
                      <div className="header-item">
                        <p>{element.title}</p>
                        <div onClick={() => handleClick(element.id_chapter)}>
                          <DeleteIcon className="icone" color="primary" />
                        </div>
                      </div>
                    </AccordionSummary>
                  </Accordion>
                ))}
              </div>
            ))}
        </div>
      </div>
      {deleted && <SimpleBackdrop />}
    </div>
  );
};
