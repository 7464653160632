import React, { useEffect, useState } from "react";

import { useData } from "../../utils/useData.tsx";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

import "../../../../assets/styles/layouts/_container-form.scss";
import "../../../../assets/styles/components/_buttonForm.scss";
import "./Edit.scss";

import EditIcon from "@mui/icons-material/Edit";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { fetchDataEdit } from "../../../../services/AdminService.ts";
import SimpleBackdrop from "../../components/Alert/Background.tsx";
import { useValidFile } from "../../utils/useValidFile.tsx";
import { Alert, Snackbar } from "@mui/material";
import { Universe } from "../../../../types/index.ts";

export const EditUniverse = () => {
  const { dataAll } = useData("characters");

  const [idCharacter, setIdCharacter] = useState(0);
  const [name, setName] = useState("");
  const [content, setContent] = useState("");
  const [alt, setAlt] = useState("");
  const [error, setError] = useState<string | null>(null);

  const [edit, setEdit] = useState(false);

  const { file, onlyImage, handleFileChange } = useValidFile();

  useEffect(() => {
    dataAll.forEach((item: Universe) => {
      setIdCharacter(item.id_characters);
      setName(item.name);
      setContent(item.content);
      setAlt(item.alt);
    });
  }, [dataAll]);

  function handleClick(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    e.preventDefault();
    const formData = new FormData();
    formData.append("name", name);
    formData.append("content", content);
    formData.append("file", file);
    formData.append("alt", alt);

    try {
      fetchDataEdit("/characters/edit/", idCharacter, formData);
      setEdit(true);
    } catch (error) {
      setError(error);
      setInterval(() => setError(null), 1000);
    }
  }
  console.log(dataAll);

  return (
    <div className="container-form book-edit edit">
      <div className="form-item">
        <div className="form-item-header">
          <div onClick={() => window.history.back()}>
            <ArrowBackIcon />
          </div>
          <h1>Editar Livro</h1>{" "}
        </div>

        <div className="container-itens-edit">
          {dataAll.length > 0 &&
            dataAll.map((item: Universe) => (
              <div className="itens-render">
                <Accordion
                  className=" summary-item"
                  sx={{ backgroundColor: "#ffffffe3" }}
                >
                  <AccordionSummary
                    expandIcon={<EditIcon className="icone" color="primary" />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                    className="summary-title"
                  >
                    <div>
                      <h2 style={{ margin: "inherit" }}>{item.name}</h2>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box>
                      <div className="book-edit-div">
                        <TextField
                          id="outlined-textarea"
                          label={"Nome"}
                          placeholder={"Nome"}
                          type="text"
                          sx={{
                            border:
                              "1px solid rgba(255, 255, 255, 0.3607843137)",
                          }}
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                        <TextField
                          id="outlined-textarea"
                          label={"Descrição da imagem"}
                          placeholder={"Descrição da imagem"}
                          type="text"
                          sx={{
                            border:
                              "1px solid rgba(255, 255, 255, 0.3607843137)",
                            marginLeft: "10px",
                          }}
                          value={alt}
                          onChange={(e) => setAlt(e.target.value)}
                        />
                      </div>
                      <div
                        className="input-page"
                        style={{ display: "inherit" }}
                      >
                        <div
                          className="input-file"
                          style={{
                            margin: "10px 8px 0px 0px",
                            border: "1px solid rgb(0 0 0 / 23%)",
                            borderRadius: "2px",
                            width: "-webkit-fill-available",
                          }}
                        >
                          <input
                            type="file"
                            accept="image/*"
                            onChange={(e) => handleFileChange(e)}
                          />
                        </div>
                      </div>
                      <TextField
                        id="outlined-multiline-static"
                        label={"Descrição do Personagem"}
                        multiline
                        rows={4}
                        onChange={(e) => setContent(e.target.value)}
                        sx={{
                          border: "1px solid rgba(255, 255, 255, 0.3607843137)",
                          margin: "10px 8px 0px 0px",
                          width: "-webkit-fill-available",
                        }}
                        value={content}
                      />
                      <button
                        className="button-form"
                        onClick={(e) => handleClick(e)}
                      >
                        Enviar
                      </button>
                    </Box>
                  </AccordionDetails>
                </Accordion>
              </div>
            ))}
        </div>
      </div>
      {edit && <SimpleBackdrop />}
      {onlyImage && (
        <div className="error">
          <Alert
            severity="error"
            sx={{
              position: "fixed",
              bottom: "5px",
              left: "43%",
              color: "black",
            }}
          >
            Apenas imagens
          </Alert>
        </div>
      )}
      {error && <Snackbar open={error != null} message={error} />}
    </div>
  );
};
