import React, { useState } from "react";

import SimpleBackdrop from "../../components/Alert/Background.tsx";

import {
  fetchDataDelete,
  fetchDataDeleteImg,
} from "../../../../services/AdminService.ts";
import { useData } from "../../utils/useData.tsx";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";

import DeleteIcon from "@mui/icons-material/Delete";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import "../../../../assets/styles/layouts/_container-form.scss";
import "./Delete.scss";
import { Universe } from "../../../../types/index.ts";
import { AccordionDetails } from "@mui/material";

export const DeleteUniverse = () => {
  const { dataAll } = useData("characters");

  const [deleted, setDeleted] = useState(false);

  const handleClick = (id: number) => {
    fetchDataDelete("/characters/delete/", id);
    setDeleted(true);
  };

  const handleClickImg = (id: number, img: string) => {
    fetchDataDeleteImg("/characters/delete/img/", id, img);
    setDeleted(true);
  };

  return (
    <div className="container-form Delete">
      <div className="form-item">
        <div className="form-item-header">
          <div onClick={() => window.history.back()}>
            <ArrowBackIcon />
          </div>
          <h1>Excluír personagem</h1>{" "}
        </div>

        <div className="container-itens-edit">
          {dataAll.length > 0 &&
            dataAll.map((item: Universe) => (
              <div className="itens-render">
                <Accordion
                  className=" summary-item"
                  sx={{ backgroundColor: "#ffffffe3" }}
                >
                  <AccordionSummary
                    aria-controls="panel1-content"
                    id="panel1-header"
                    className="summary-title"
                    sx={{ transform: "inherit" }}
                  >
                    <div className="header-item">
                      <div>
                        <h3 style={{ margin: "inherit" }}>{item.name}</h3>
                      </div>
                      <div onClick={() => handleClick(item.id_characters)}>
                        <DeleteIcon className="icone" color="primary" />
                      </div>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    {!item &&
                      item.file.split(",").map((img) => (
                        <div
                          className="header-item"
                          style={{ marginBlock: 10 }}
                        >
                          <div>
                            <h3 style={{ margin: "inherit" }}>
                              {img.length !== 0 && img.slice(img.indexOf("_"))}
                            </h3>
                          </div>
                          <div
                            onClick={() =>
                              handleClickImg(item.id_characters, img)
                            }
                            style={{ cursor: "pointer" }}
                          >
                            <DeleteIcon className="icone" color="primary" />
                          </div>
                        </div>
                      ))}
                  </AccordionDetails>
                </Accordion>
              </div>
            ))}
        </div>
      </div>
      {deleted && <SimpleBackdrop />}
    </div>
  );
};
