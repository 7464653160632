import React from "react";

import { Outlet } from "react-router-dom";
import { Header } from "./components/Header/Header.tsx";

import "./assets/styles/main.scss";
import { Menu } from "./components/Menu/Menu.tsx";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <div className="App">
        <Header />
        <Menu />
        <Outlet />
      </div>
    </QueryClientProvider>
  );
}

export default App;
