import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { Box, Snackbar } from "@mui/material";

export default function SimpleBackdrop(setActive: any & boolean) {
  const [timeOut, setTimeOut] = React.useState(false);
  const vertical = "bottom";
  const horizontal = "center";

  setTimeout(() => {
    setTimeOut(true);
    setInterval(() => {
      window.location.reload();
    }, 1000);
  }, 1000);
  return (
    <div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={setActive}
      >
        <CircularProgress color="inherit" />
        {timeOut && (
          <Box>
            <Snackbar
              message="Atualização realizada com Sucesso!"
              open={true}
              anchorOrigin={{ vertical, horizontal }}
            />
          </Box>
        )}
      </Backdrop>
    </div>
  );
}
