import React from "react";

import "../assets/styles/layouts/_home.scss";
import { Outlet } from "react-router-dom";

export const BookInfo = () => {
  return (
    <div className="container-home">
      <main className="container-home-content">
        <Outlet />
      </main>
    </div>
  );
};
