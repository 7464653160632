import { useEffect, useState } from "react";

import { useBookService } from "../services/useBookService.ts";

export const useData = <T,>(endPoint: string) => {
  const { data, isLoading } = useBookService(endPoint);
  const [dataAll, setDataAll] = useState<T[]>([]);

  useEffect(() => {
    try {
      if (data) {
        setDataAll(data);
      }
    } catch (error) {
      throw error;
    }
  }, [data]);

  return { dataAll, isLoading };
};
