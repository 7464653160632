import React, { useState } from "react";

import "./Wall.scss";
import "../../assets/styles/layouts/_container.scss";
import "../../assets/styles/components/_primaryButton.scss";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import logo from "../../assets/images/logo.jpg";

import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

import { useData } from "../../utils/useData.tsx";

import { NewsItem } from "../../types/index.ts";

export const Wall = () => {
  const { dataAll } = useData<NewsItem>("news");
  const [controll, setControll] = useState(0);
  // const scrollDiv = useRef<HTMLDivElement>(null);

  const handlePrev = () => {
    // if (scrollDiv.current) {
    //   scrollDiv.current.scrollBy({ left: 100 });
    // }
    if (controll > 0) {
      setControll((prev) => prev - 1);
    }
  };

  const handleNext = () => {
    // if (scrollDiv.current) {
    //   scrollDiv.current.scrollLeft -= 100;
    // }
    if (controll < dataAll.length - 1) {
      setControll((prev) => prev + 1);
    }
  };

  return (
    <div className="container-itens">
      <div className="wall-title">
        <h2>
          <u>Mural</u>
        </h2>
      </div>

      <div className="container-wall-content">
        <div className="wall-content-image">
          {dataAll.length > 0 && (
            <>
              <div className="wall-content">
                <h3>{dataAll[controll]?.title}</h3>
                <div className="wall-scroll">
                  <p>{dataAll[controll]?.newscol}</p>
                </div>
              </div>
              <div className="wall-image">
                {dataAll[controll]?.file ? (
                  <img
                    src={`${process.env.REACT_APP_HOST_IMAGE}${dataAll[controll]?.file}`}
                    alt={dataAll[controll]?.alt}
                    width={510}
                    height={320}
                  />
                ) : (
                  <img
                    src={logo}
                    alt={dataAll[controll]?.alt}
                    width={510}
                    height={320}
                  />
                )}
              </div>
            </>
          )}
        </div>
      </div>

      <div className="wall-index-item">
        <button
          className="primary"
          onClick={handlePrev}
          disabled={controll <= 0}
        >
          <span>
            <NavigateBeforeIcon fontSize="large" />
          </span>
        </button>
        <div className="wall-index-div"></div>
        <button
          className="primary"
          onClick={handleNext}
          disabled={controll >= dataAll.length - 1}
        >
          <span>
            <NavigateNextIcon fontSize="large" />
          </span>
        </button>
      </div>
    </div>
  );
};
