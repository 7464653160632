import React, { useState } from "react";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

import EditIcon from "@mui/icons-material/Edit";
import "../../../../assets/styles/layouts/_container-form.scss";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import "./Edit.scss";
import BasicModal from "../../components/Modal/Modal.tsx";

import { useData } from "../../utils/useData.tsx";
import { BookEdit } from "../../types/index.ts";

export const Edit = () => {
  const { dataAll } = useData("book");

  const [openModal, setOpenModal] = useState("");
  const [numberPage, setNumberPage] = useState(0);
  const [idPage, setIdPage] = useState(0);
  const [chapter, setChapter] = useState(0);
  const [content, setContent] = useState("");
  const [alt, setAlt] = useState("");

  const handleClick = (
    idPage: number,
    number: number,
    content: string,
    chapter: number,
    alt: string
  ) => {
    setOpenModal("active");
    setIdPage(idPage);
    setNumberPage(number);
    setChapter(chapter);
    setContent(content);
    setAlt(alt);
  };

  return (
    <div className="container-form edit edit-page">
      <div className="form-item">
        <div className="form-item-header">
          <div onClick={() => window.history.back()}>
            <ArrowBackIcon />
          </div>
          <h1>Editar Páginas</h1>{" "}
        </div>

        <div className="container-itens-edit">
          {dataAll.length > 0 &&
            dataAll.map((item: BookEdit) => (
              <div className="itens-render">
                <h2>Livro: {item.title}</h2>
                <p>Capítulos: </p>
                {item.chapters.map((element) => (
                  <Accordion
                    className=" summary-item"
                    key={element.id_chapter}
                    sx={{ backgroundColor: "#ffffffe3" }}
                  >
                    <AccordionSummary
                      expandIcon={
                        <EditIcon className="icone" color="primary" />
                      }
                      aria-controls="panel1-content"
                      id="panel1-header"
                      className="summary-title"
                      key={element.id_chapter}
                    >
                      <p>{element.title}</p>
                    </AccordionSummary>
                    <AccordionDetails>
                      {element.pages.map((pages) => (
                        <div className="form-inputs">
                          <AccordionDetails
                            key={pages.id_page}
                            onClick={() =>
                              handleClick(
                                pages.id_page,
                                pages.number,
                                pages.content,
                                element.number,
                                pages.alt
                              )
                            }
                          >
                            <div>
                              <p>Página {pages.number}</p>
                            </div>
                          </AccordionDetails>
                        </div>
                      ))}
                    </AccordionDetails>
                  </Accordion>
                ))}
              </div>
            ))}
        </div>
      </div>
      <BasicModal
        openModal={openModal}
        setModal={setOpenModal}
        idPage={idPage}
        number={numberPage}
        content={content}
        chapter={chapter}
        alt={alt}
      />
    </div>
  );
};
