import React from "react";
import { Summary } from "../Summary/Summary.tsx";

import "../../assets/styles/layouts/_container.scss";
import "./Book.scss";
import "../../assets/styles/components/_primaryButton.scss";

import { useData } from "../../utils/useData.tsx";
import { BookProps } from "../Admin/types/index.ts";

export const Book = () => {
  const { dataAll } = useData<BookProps>("book");

  return (
    <div className="container-itens">
      <div className="book-title">
        <h2>
          <u>Livro</u>
        </h2>
      </div>
      <div className="book-content-image">
        <div className="book-image">
          <img
            src={`${process.env.REACT_APP_HOST_IMAGE}${dataAll[0]?.file}`}
            alt={dataAll[0]?.alt}
            width={320}
            height={450}
          />
        </div>
        <div className="book-summary">
          <div className="title-summary">
            <h2>{dataAll[0]?.title}</h2>
          </div>
          <br />
          <p>{dataAll[0]?.sinopse}</p>
        </div>
      </div>
      <div className="summary">
        <h3>
          <u>Sumário</u>
        </h3>
        <div className="sumarioIndice">
          <Summary />
        </div>
      </div>
    </div>
  );
};
