import React, { useState } from "react";

import "./Menu.scss";

import menu_icon from "../../assets/images/menu_icon.svg";
import ep_close from "../../assets/images/ep_close.svg";
import { Link } from "react-router-dom";

import HomeIcon from "@mui/icons-material/Home";
import BookIcon from "@mui/icons-material/Book";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";

export const Menu = () => {
  const [visible, setVisible] = useState("");
  const [hidden, setHidden] = useState("");

  const handleClickOpenMenu = () => {
    setVisible("active");
    setHidden("hidden");
  };

  const handleClickCloseMenu = () => {
    setVisible(" ");
    setHidden(" ");
  };

  return (
    <div className="container-menu">
      <div className="menu-background">
        <div
          className={`menu-interaction ${hidden}`}
          onClick={() => handleClickOpenMenu()}
        >
          <img src={menu_icon} alt="Um menu em formato de Hamburger" />
        </div>
        <div className={`menu-itens ${visible}`}>
          <div
            className={`menu-interaction close ${visible}`}
            onClick={() => handleClickCloseMenu()}
          >
            <img
              src={ep_close}
              alt="Um menu em formato de Hamburger"
              width={71}
              height={48}
            />
          </div>
          <ul>
            <li onClick={() => handleClickCloseMenu()}>
              <Link to="/">
                {" "}
                <HomeIcon /> Inicio
              </Link>
            </li>
            <li onClick={() => handleClickCloseMenu()}>
              <Link to="/book">
                <BookIcon /> Livro
              </Link>
            </li>
            <li onClick={() => handleClickCloseMenu()}>
              <Link to="/universe">
                <TravelExploreIcon /> Universo
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
