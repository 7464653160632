import React from "react";
import { Link } from "react-router-dom";

import { useData } from "../../utils/useData.tsx";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";

import "./Summary.scss";
import { Chapter } from "../../types/index.ts";

export const Summary = () => {
  const { dataAll } = useData<Chapter>("chapters");

  return (
    <div className="container-summary">
      {Array.isArray(dataAll) &&
        dataAll.map(
          (element) =>
            element.pages.length > 0 && (
              <Link
                to={`/book/chapter/id=${element.number}`}
                key={element.id_chapter}
              >
                <Accordion className=" summary-item" key={element.id_chapter}>
                  <AccordionSummary
                    expandIcon={<DoubleArrowIcon className="icone" />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                    className="summary-title"
                  >
                    <span>{element?.number}</span> {element?.title}
                  </AccordionSummary>
                  <AccordionDetails></AccordionDetails>
                </Accordion>
              </Link>
            )
        )}
    </div>
  );
};
