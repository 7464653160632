import React from "react";

import { UniverseContent } from "../components/universe/Universe.tsx";

import "../assets/styles/layouts/_home.scss";

export const Universe = () => {
  return (
    <div className="container-home">
      <main
        className="container-home-content"
        style={{ overflowY: "hidden", marginBottom: "50px" }}
      >
        <UniverseContent />
      </main>
    </div>
  );
};
