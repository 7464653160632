import * as React from "react";

import SimpleBackdrop from "../Alert/Background.tsx";

import { fetchDataEdit } from "../../../../services/AdminService.ts";

import "./FormEdit.scss";
import "../../../../assets/styles/components/_buttonForm.scss";

import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { Alert, Snackbar } from "@mui/material";
import { PropsFormEdit } from "../../types/index.ts";
import { useValidFile } from "../../utils/useValidFile.tsx";

export default function MultilineTextFields({
  idPage,
  type,
  referenceValue,
  currentValue,
  textTitle,
  contentEdit,
  chapterNumber,
  pageNumber,
  altProps,
}: PropsFormEdit) {
  const [reference, setReference] = React.useState(0);
  const [current, setCurrent] = React.useState(0);
  const [content, setContent] = React.useState("");
  const [alt, setAlt] = React.useState("");

  const { file, onlyImage, handleFileChange } = useValidFile();

  const [activeBackGround, setActiveBackGround] = React.useState(false);

  const [error, setError] = React.useState<string | null>(null);

  React.useEffect(() => {
    setContent(contentEdit);
    setCurrent(pageNumber);
    setReference(chapterNumber);
    setAlt(altProps);
  }, [pageNumber, contentEdit, altProps, chapterNumber]);

  function handleClick(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    e.preventDefault();
    if (type === "page") {
      const formData = new FormData();

      formData.append("chapter", reference.toString());
      formData.append("number", current.toString());
      formData.append("file", file);
      formData.append("alt", alt);
      formData.append("content", content);

      handleTryCath("pages/edit/", formData);
    } else {
      const objectData = {
        book: referenceValue,
        title: content,
        number: current,
      };
      handleTryCath("chapters/edit/", objectData);
    }
  }

  const handleTryCath = async (endPoint: string, objectData: Object) => {
    try {
      await fetchDataEdit(endPoint, idPage, objectData);
      setActiveBackGround(true);
    } catch (err) {
      console.log(err, "err");

      setError(err);
      setInterval(() => setError(null), 2000);
    }
  };

  return (
    <Box
      component="form"
      sx={{
        "& .MuiTextField-root": { m: 1, width: "25ch" },
      }}
      noValidate
      autoComplete="off"
    >
      <div className="box-border">
        <div className="box-border-edit">
          <div className="page-edit-mobile">
            <TextField
              id="outlined-textarea"
              label={referenceValue}
              type="number"
              value={reference}
              onChange={(e) => setReference(parseInt(e.target.value))}
            />
            <TextField
              id="outlined-textarea"
              label={currentValue}
              type="number"
              onChange={(e) => setCurrent(parseInt(e.target.value))}
              value={current}
            />
          </div>
          {type === "page" && (
            <div className="input-page">
              <div className="input-file">
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) => handleFileChange(e)}
                />
              </div>

              <TextField
                id="outlined-textarea"
                label={"Descrição da imagem"}
                placeholder={"Descrição da imagem"}
                type="text"
                onChange={(e) => setAlt(e.target.value)}
                value={alt}
              />
            </div>
          )}
        </div>
        <div className="text-field">
          <TextField
            id="outlined-multiline-static"
            label={textTitle}
            multiline
            rows={7}
            onChange={(e) => setContent(e.target.value)}
            value={content}
          />
        </div>
      </div>
      <button className="button-form" onClick={(e) => handleClick(e)}>
        Editar
      </button>
      {activeBackGround && <SimpleBackdrop setActive={activeBackGround} />}
      {error && (
        <div className="alert-error">
          <Snackbar
            open={error !== null}
            message={error}
            sx={{ border: "none" }}
            autoHideDuration={2000}
          />
        </div>
      )}
      {onlyImage && (
        <div className="error">
          <Alert
            severity="error"
            sx={{
              position: "fixed",
              bottom: "5px",
              left: "43%",
              color: "black",
            }}
          >
            Apenas imagens
          </Alert>
        </div>
      )}
    </Box>
  );
}
