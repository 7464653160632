import React from "react";
import { createBrowserRouter } from "react-router-dom";

import App from "../App.tsx";
import { BookInfo } from "../pages/BookInfo.tsx";
import { Home } from "../pages/Home.tsx";
import { Book } from "../components/Book/Book.tsx";
import { BookPages } from "../components/BookPages/BookPages.tsx";
import { Admin } from "../pages/Admin.tsx";
import { Inicial } from "../components/Admin/Inicial/Inicial.tsx";
import { Post } from "../components/Admin/Page/Post/Post.tsx";
import { Edit } from "../components/Admin/Page/Edit/Edit.tsx";
import { Delete } from "../components/Admin/Page/Delete/Delete.tsx";
import { PostBook } from "../components/Admin/Book/Post/Post.tsx";
import { EditBook } from "../components/Admin/Book/Edit/Edit.tsx";
import { DeleteBook } from "../components/Admin/Book/Delete/Delete.tsx";
import { PostChapter } from "../components/Admin/Chapter/Post/Post.tsx";
import { EditChapter } from "../components/Admin/Chapter/Edit/Edit.tsx";
import { DeleteChapter } from "../components/Admin/Chapter/Delete/Delete.tsx";
import { Universe } from "../pages/Universe.tsx";
import { PostNews } from "../components/Admin/News/Post/Post.tsx";
import { EditNews } from "../components/Admin/News/Edit/Edit.tsx";
import { DeleteNews } from "../components/Admin/News/Delete/Delete.tsx";
import { PostUniverse } from "../components/Admin/Universe/Post/Post.tsx";
import { EditUniverse } from "../components/Admin/Universe/Edit/Edit.tsx";
import { DeleteUniverse } from "../components/Admin/Universe/Delete/Delete.tsx";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "book",
        element: <BookInfo />,
        children: [
          {
            path: "/book",
            element: <Book />,
          },
          {
            path: "/book/chapter/:id",
            element: <BookPages />,
          },
        ],
      },
      {
        path: "universe",
        element: <Universe />,
      },
    ],
  },
  {
    path: "/admin",
    element: <Admin />,
    children: [
      {
        path: "/admin",
        element: <Inicial />,
      },
      {
        path: "/admin/page",
        children: [
          {
            path: "/admin/page/add",
            element: <Post />,
          },
          {
            path: "/admin/page/edit",
            element: <Edit />,
          },
          {
            path: "/admin/page/delete",
            element: <Delete />,
          },
        ],
      },
      {
        path: "/admin/book",
        children: [
          {
            path: "/admin/book/add",
            element: <PostBook />,
          },
          {
            path: "/admin/book/edit",
            element: <EditBook />,
          },
          {
            path: "/admin/book/delete",
            element: <DeleteBook />,
          },
        ],
      },
      {
        path: "/admin/news",
        children: [
          {
            path: "/admin/news/add",
            element: <PostNews />,
          },
          {
            path: "/admin/news/edit",
            element: <EditNews />,
          },
          {
            path: "/admin/news/delete",
            element: <DeleteNews />,
          },
        ],
      },
      {
        path: "/admin/chapter",
        children: [
          {
            path: "/admin/chapter/add",
            element: <PostChapter />,
          },
          {
            path: "/admin/chapter/edit",
            element: <EditChapter />,
          },
          {
            path: "/admin/chapter/delete",
            element: <DeleteChapter />,
          },
        ],
      },
      {
        path: "/admin/universe",
        children: [
          {
            path: "/admin/universe/add",
            element: <PostUniverse />,
          },
          {
            path: "/admin/universe/edit",
            element: <EditUniverse />,
          },
          {
            path: "/admin/universe/delete",
            element: <DeleteUniverse />,
          },
        ],
      },
    ],
  },
]);
