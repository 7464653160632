import { useEffect, useState } from "react";
import { useBookService } from "../../../services/useBookService.ts";

export const useData = (endPoint: string) => {
  const { data, isLoading } = useBookService(endPoint);
  const [dataAll, setDataAll] = useState([]);

  useEffect(() => {
    if (data) {
      setDataAll(data);
    }
  }, [data]);

  return { dataAll, isLoading };
};
