import React from "react";

import "../assets/styles/layouts/_home.scss";
import { Wall } from "../components/Wall/Wall.tsx";

export const Home = () => {
  return (
    <div className="container-home">
      <main className="container-home-content">
        <Wall />
      </main>
    </div>
  );
};
