import React from "react";
import Cookies from "js-cookie";

import { Outlet } from "react-router-dom";
import { Login } from "../components/Admin/Login/Login.tsx";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const queryClient = new QueryClient();

export const Admin = () => {
  const token = Cookies.get("authToken");
  return (
    <QueryClientProvider client={queryClient}>
      <div>{token ? <Outlet /> : <Login />}</div>
    </QueryClientProvider>
  );
};
