import React from "react";

import RecipeReviewCard from "../Cards/Cards.tsx";
import "./Universe.scss";

import { useData } from "../../utils/useData.tsx";

import { Universe } from "../../types/index.ts";

export const UniverseContent = () => {
  const { dataAll } = useData<Universe>("characters");

  return (
    <div className="container-itens universe">
      <h1>Universo de Éligos: The Sacred Guardian of Magic</h1>
      <p>Conheça um pouco mais sobre os personagens</p>
      <div className="item">
        {dataAll.length <= 0 && <p>Nenhum personagem adicionado ainda</p>}
        {dataAll.length > 0 &&
          dataAll.map((character) => (
            <RecipeReviewCard
              key={character.id_characters}
              id={character.id_characters}
              name={character.name}
              content={character.content}
              alt={character.alt}
              file={character.file}
            />
          ))}
      </div>
    </div>
  );
};
