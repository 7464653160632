import React, { useEffect, useState } from "react";

import { useData } from "../../utils/useData.tsx";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

import "../../../../assets/styles/layouts/_container-form.scss";
import "../../../../assets/styles/components/_buttonForm.scss";
import "../../../../assets/styles/components/_buttonFile.scss";
import "./Edit.scss";

import EditIcon from "@mui/icons-material/Edit";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { fetchDataEdit } from "../../../../services/AdminService.ts";
import SimpleBackdrop from "../../components/Alert/Background.tsx";
import { Alert } from "@mui/material";

import { News } from "../../types/index.ts";

import { useValidFile } from "../../utils/useValidFile.tsx";

export const EditNews = () => {
  const { dataAll } = useData("news");

  const [idNews, setIDNews] = useState(0);
  const [title, setTitle] = useState("");
  const [newscol, setNewscol] = useState("");
  const [alt, setAlt] = useState("");

  const { file, onlyImage, handleFileChange } = useValidFile();

  const [edit, setEdit] = useState(false);

  const [error, setError] = useState();
  useEffect(() => {
    try {
      dataAll.forEach((item: News) => {
        setIDNews(item.idnews);
        setTitle(item.title);
        setNewscol(item.newscol);
        setAlt(item.alt);
      });
    } catch (error) {
      setError(error);
    }
  }, [dataAll]);

  function handleClick(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    e.preventDefault();
    const formData = new FormData();
    formData.append("title", title);
    formData.append("newscol", newscol);
    formData.append("file", file);
    formData.append("alt", alt);

    fetchDataEdit("/news/edit/", idNews, formData);
    setEdit(true);
  }

  return (
    <div className="container-form edit">
      <div className="form-item">
        <div className="form-item-header">
          <div onClick={() => window.history.back()}>
            <ArrowBackIcon />
          </div>
          <h1>Editar Novidade</h1>{" "}
        </div>

        <div className="container-itens-edit">
          {dataAll.length > 0 &&
            dataAll.map((item: News) => (
              <div className="itens-render">
                <Accordion
                  className=" summary-item"
                  sx={{ backgroundColor: "#ffffffe3" }}
                >
                  <AccordionSummary
                    expandIcon={<EditIcon className="icone" color="primary" />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                    className="summary-title"
                  >
                    <div>
                      <h2 style={{ margin: "inherit" }}>{item.title}</h2>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box>
                      <div>
                        <div className="edit-div">
                          <TextField
                            id="outlined-textarea"
                            label={"Adicionar Novidades"}
                            placeholder={"Adicionar Novidades"}
                            type="text"
                            sx={{
                              border:
                                "1px solid rgba(255, 255, 255, 0.3607843137)",
                              marginRight: "16px",
                            }}
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                          />
                          <TextField
                            id="outlined-textarea"
                            label={"Descrição da imagem"}
                            placeholder={"Descrição da imagem"}
                            type="text"
                            sx={{
                              border:
                                "1px solid rgba(255, 255, 255, 0.3607843137)",
                            }}
                            value={alt}
                            onChange={(e) => setAlt(e.target.value)}
                          />
                        </div>

                        <div
                          className="input-page"
                          style={{ display: "inherit" }}
                        >
                          <div
                            className="input-file"
                            style={{
                              border: "1px solid rgb(149 143 143 / 71%)",
                              width: "100%",
                              margin: "10px 0px 0px 0px",
                            }}
                          >
                            <input
                              type="file"
                              accept="image/*"
                              onChange={(e) => handleFileChange(e)}
                            />
                          </div>
                        </div>
                        <TextField
                          id="outlined-textarea"
                          label={"conteúdo"}
                          placeholder={"conteúdo"}
                          multiline
                          rows={4}
                          sx={{
                            border:
                              "1px solid rgba(255, 255, 255, 0.3607843137)",
                            marginTop: "10px",
                            width: "100%",
                          }}
                          value={newscol}
                          onChange={(e) => setNewscol(e.target.value)}
                        />
                      </div>

                      <button
                        className="button-form"
                        onClick={(e) => handleClick(e)}
                        style={{ marginRight: 0 }}
                      >
                        Enviar
                      </button>
                    </Box>
                  </AccordionDetails>
                </Accordion>
              </div>
            ))}
        </div>
      </div>
      {edit && <SimpleBackdrop setActive={edit} />}
      {error && <SimpleBackdrop setActive={error} />}
      {onlyImage && (
        <div className="error">
          <Alert
            severity="error"
            sx={{
              position: "fixed",
              bottom: "5px",
              left: "43%",
              color: "black",
            }}
          >
            Apenas imagens
          </Alert>
        </div>
      )}
    </div>
  );
};
