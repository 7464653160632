import * as React from "react";

import MultilineTextFields from "../FormEdit/FormEdit.tsx";
import CloseIcon from "@mui/icons-material/Close";

import "./Modal.scss";
import { PropsModal } from "../../types/index.ts";

export default function BasicModal(props: PropsModal) {
  const handleClickClose = () => {
    props.setModal("");
  };

  return (
    <div className={`container-modal ${props.openModal}`}>
      <div className="modal">
        <div className="header-edit">
          <h2>Página {props.number}</h2>
          <div onClick={() => handleClickClose()}>
            <CloseIcon />
          </div>
        </div>
        <MultilineTextFields
          idPage={props.idPage}
          type="page"
          referenceValue="Número do capítulo"
          currentValue="Número da página"
          textTitle="Texto/Conteúdo"
          pageNumber={props.number}
          chapterNumber={props.chapter}
          contentEdit={props.content}
          altProps={props.alt}
        />
      </div>
    </div>
  );
}
