import React, { useState } from "react";

import { Alert, Box, Snackbar, TextField } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import "../../../../assets/styles/layouts/_container-form.scss";
import "../../../../assets/styles/components/_buttonForm.scss";
import "./Post.scss";

import SimpleBackdrop from "../../components/Alert/Background.tsx";
import { fetchDataNewItem } from "../../../../services/AdminService.ts";
import { useValidFile } from "../../utils/useValidFile.tsx";

const styles = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  backgroundColor: "black",
  borderRadius: "30px",
  border: "1px solid white",
  padding: "30px 20px",
  boxShadow: "0px 0px 4px 0px white",
};

export const PostUniverse = () => {
  const [post, setPost] = useState(false);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [alt, setAlt] = useState("");
  const [error, setError] = useState<string | null>(null);

  const { file, onlyImage, handleFileChange } = useValidFile();

  async function handleClick(
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) {
    e.preventDefault();
    const formData = new FormData();
    formData.append("name", name);
    formData.append("content", description);
    formData.append("file", file);
    formData.append("alt", alt);

    try {
      await fetchDataNewItem("/characters", formData);
      setPost(true);
    } catch (err) {
      setError(err.response.data);

      setInterval(() => setError(null), 2000);
    }
  }

  return (
    <div className="container-form book-add container">
      <Box sx={styles}>
        <div className="back-nav" onClick={() => window.history.back()}>
          <span>
            <ArrowBackIcon />
          </span>
        </div>
        <h1>Adicionar um novo Personagem</h1>

        <div className="add-div">
          <TextField
            id="outlined-textarea"
            label={"Nome do Personagem"}
            placeholder={"Nome do Personagem"}
            type="text"
            sx={{
              border: "1px solid rgba(255, 255, 255, 0.3607843137)",
            }}
            value={name}
            onChange={(e) => setName(e.target.value)}
          />

          <TextField
            id="outlined-textarea"
            label={"Descrição da imagem"}
            placeholder={"Descrição da imagem"}
            type="text"
            sx={{
              border: "1px solid rgba(255, 255, 255, 0.3607843137)",
              marginLeft: "10px",
            }}
            value={alt}
            onChange={(e) => setAlt(e.target.value)}
          />
        </div>
        <div className="input-page" style={{ display: "inherit" }}>
          <div className="input-file" style={{ marginRight: 0 }}>
            <input
              type="file"
              accept="image/*"
              onChange={(e) => handleFileChange(e)}
            />
          </div>
        </div>
        <TextField
          id="outlined-multiline-static"
          label={"Descrição do Personagem"}
          multiline
          rows={4}
          onChange={(e) => setDescription(e.target.value)}
          sx={{
            border: "1px solid rgba(255, 255, 255, 0.3607843137)",
            marginTop: "10px",
          }}
          value={description}
        />
        <button
          className="button-form"
          onClick={(e) => handleClick(e)}
          style={{ marginRight: 0 }}
        >
          Enviar
        </button>
      </Box>
      {post && <SimpleBackdrop />}
      {onlyImage && (
        <div className="error">
          <Alert
            severity="error"
            sx={{
              position: "fixed",
              bottom: "5px",
              left: "43%",
              color: "black",
            }}
          >
            Apenas imagens
          </Alert>
        </div>
      )}
      {error && (
        <div className="alert-error">
          <Snackbar
            open={error !== null}
            message={error}
            sx={{ border: "none" }}
          />
        </div>
      )}
    </div>
  );
};
