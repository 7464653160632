import React from "react";

import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";

import "./Cards.scss";
import { Link } from "react-router-dom";
export const Cards = ({
  title,
  hrefPost,
  hrefEdit,
  hrefDelete,
  description,
}) => {
  return (
    <div className="Card">
      <h2>{title}</h2>
      <p>{description}</p>
      <Stack direction="row" spacing={1}>
        <IconButton color="primary" aria-label="add to shopping cart">
          <Link to={hrefPost}>
            <AddIcon color="primary" />
          </Link>
        </IconButton>
        <IconButton color="primary" aria-label="add an alarm">
          <Link to={hrefEdit}>
            <EditIcon color="primary" />
          </Link>
        </IconButton>
        <IconButton aria-label="delete" color="primary">
          <Link to={hrefDelete}>
            <DeleteIcon color="primary" />
          </Link>
        </IconButton>
      </Stack>
    </div>
  );
};
