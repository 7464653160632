import React, { useState } from "react";

import SimpleBackdrop from "../../components/Alert/Background.tsx";

import { useData } from "../../utils/useData.tsx";
import { fetchDataDelete } from "../../../../services/AdminService.ts";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";

import DeleteIcon from "@mui/icons-material/Delete";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import "../../../../assets/styles/layouts/_container-form.scss";
import "./Delete.scss";
import { News } from "../../types/index.ts";

export const DeleteNews = () => {
  const { dataAll } = useData("news");

  const [deleted, setDeleted] = useState(false);

  const handleClick = (id: number) => {
    fetchDataDelete("news/delete/", id);
    setDeleted(true);
  };

  return (
    <div className="container-form Delete">
      <div className="form-item">
        <div className="form-item-header">
          <div onClick={() => window.history.back()}>
            <ArrowBackIcon />
          </div>
          <h1>Excluír novidades antigas</h1>{" "}
        </div>

        <div className="container-itens-edit">
          {dataAll.length > 0 &&
            dataAll.map((item: News) => (
              <div className="itens-render">
                <Accordion
                  className=" summary-item"
                  sx={{ backgroundColor: "#ffffffe3" }}
                >
                  <AccordionSummary
                    aria-controls="panel1-content"
                    id="panel1-header"
                    className="summary-title"
                    sx={{ transform: "inherit" }}
                  >
                    <div className="header-item">
                      <div>
                        <h2 style={{ margin: "inherit" }}>{item.newscol}</h2>
                      </div>
                      <div onClick={() => handleClick(item.idnews)}>
                        <DeleteIcon className="icone" color="primary" />
                      </div>
                    </div>
                  </AccordionSummary>
                </Accordion>
              </div>
            ))}
        </div>
      </div>
      {deleted && <SimpleBackdrop />}
    </div>
  );
};
