import React, { useState } from "react";

import SimpleBackdrop from "../../components/Alert/Background.tsx";

import { fetchDataDelete } from "../../../../services/AdminService.ts";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

import DeleteIcon from "@mui/icons-material/Delete";
import ClearIcon from "@mui/icons-material/Clear";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import "../../../../assets/styles/layouts/_container-form.scss";
import "./Delete.scss";
import { useData } from "../../utils/useData.tsx";
import { BookEdit } from "../../types/index.ts";

export const Delete = () => {
  const { dataAll } = useData("book");

  const [deleted, setDeleted] = useState(false);

  const handleClick = (id: number) => {
    fetchDataDelete("/pages/delete/", id);
    setDeleted(true);
  };

  return (
    <div className="container-form Delete">
      <div className="form-item">
        <div className="form-item-header">
          <div onClick={() => window.history.back()}>
            <ArrowBackIcon />
          </div>
          <h1>Excluír Páginas</h1>{" "}
        </div>

        <div className="container-itens-edit">
          {dataAll.length > 0 &&
            dataAll.map((item: BookEdit) => (
              <div className="itens-render">
                <h2>Livro: {item.title}</h2>
                <p>Capítulos: </p>
                {item.chapters.map((element) => (
                  <Accordion
                    className=" summary-item"
                    key={element.id_chapter}
                    sx={{ backgroundColor: "#ffffffe3" }}
                  >
                    <AccordionSummary
                      aria-controls="panel1-content"
                      id="panel1-header"
                      className="summary-title"
                      key={element.id_chapter}
                      sx={{ transform: "inherit" }}
                    >
                      <div className="header-item">
                        <p>{element.title}</p>
                        <DeleteIcon className="icone" color="primary" />
                      </div>
                    </AccordionSummary>
                    <AccordionDetails>
                      {element.pages.map((pages) => (
                        <div className="form-inputs">
                          <AccordionDetails key={pages.id_page}>
                            <div className="remove-item">
                              <p>Página {pages.number}</p>
                              <div onClick={() => handleClick(pages.id_page)}>
                                <ClearIcon />
                              </div>
                            </div>
                          </AccordionDetails>
                        </div>
                      ))}
                    </AccordionDetails>
                  </Accordion>
                ))}
              </div>
            ))}
        </div>
      </div>
      {deleted && <SimpleBackdrop />}
    </div>
  );
};
